<template>
  <div :class="'breadcrumbs'" v-if="list">
    <NuxtLink
      :to="backLink"
      class="item back"
      >
      <i class="icon-angle-left"></i>
      Back to {{ backName }}
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">

interface PageProps {
  list: Array<any>,
}

const props = withDefaults(defineProps<PageProps>(), {
  list: [],
})

const backLink = computed(() => {
  if (props.list?.length < 2) return '/'
  return props.list[props.list.length - 2].link
})

const backName = computed(() => {
  if (props.list?.length < 2) return '/'
  return props.list[props.list.length - 2].name
})

</script>

<style scoped>
.breadcrumbs {
  color: var(--gd-link-color);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  width: 100%;
}

.breadcrumbs.right{
  justify-content: flex-end;
}
.breadcrumbs .item{
  color: var(--gd-link-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;  
  flex-shrink: 0;
}
.breadcrumbs .item:last-child{
  flex-shrink: 1;
  max-width: 70%;
}
.breadcrumbs i{
  vertical-align: middle;
} 


@container pb (max-width: 600px) {
  .breadcrumbs{
    justify-content: flex-start;
  }
}


 


</style>